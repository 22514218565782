import React from 'react'
import { Helmet } from 'react-helmet'

const Head = () => {
    return (
        <Helmet>
            <meta charSet="utf-8"/>
            <title>J.C.R.</title>
            <meta name="description" content="John Christian Rose is a designer and art director based in New York City."></meta>
            <meta name="twitter:card" value="summary"></meta>
            <meta name="twitter:site" content="@xtyboi"></meta>
            <meta name="twitter:title" content="J.C.R."></meta>
            <meta name="twitter:description" content="John Christian Rose is a designer and art director based in New York City."></meta>
            <meta name="twitter:creator" content="@xtyboi"></meta>
            <meta name="twitter:image" content="social.jpg"></meta>
            <meta property="og:title" content="J.C.R."></meta>
            <meta property="og:description" content="John Christian Rose is a designer and art director based in New York City."></meta>
            <meta property="og:image" content="social.jpg"></meta>
            <meta property="og:image:url" content="social.jpg"/>
            <meta property="og:url" content="https://jcr.nyc"></meta>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
            <meta http-equiv="X-UA-Compatible" content="ie=edge"></meta>
        </Helmet>
    )
}

export default Head